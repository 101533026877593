select {
  display: block;
  margin: 10px auto;
  font-size: 28px;
  max-width:90%;
}

h2 {
  width: 80%;
  margin: 0 auto;
  line-height: 1.5;
}

img {
  max-width: 40%;
}
